import { createContext } from 'react';
import getConfig from 'next/config';

import { IMenu, ILink, IGridLink } from '../components/common';
import { ButtonType } from '../components/Button';

const { publicRuntimeConfig } = getConfig();

export interface ICookies {
  [key: string]: string;
}

const listBadUrl: string[] = [
  'http://ahs.staging.dat4pro.com',
  'https://ahs.staging.dat4pro.com',
  'https://ah-inc.com',
  'https://www.ahs-inc.com',
];

export const MenuContext = createContext<IMenu[]>([]);

export const MakeReactKey = (name: string): string => {
  const newName = name.replace(/\s/g, '').toLowerCase();
  const microtime = new Date().getTime();
  return `${newName}_${microtime}`;
};

export const GetUrlFromEntryId = (menus: IMenu[], entryId: number): string => {
  const entry:number = parseInt(entryId as unknown as string);
  let output = '';
  const topLevel = menus.find(item => item.id === entry);
  if (topLevel !== undefined) {
    if (topLevel.slug === undefined || topLevel.slug === null) {
      output = '/' + topLevel.slug;
    } else {
      output = topLevel.slug;
    }
  } else {
    menus?.forEach(({ slug, submenu }: IMenu) => {
      const parentSlug = slug;
      const midLevel = submenu?.find(item => item.id === entry);
      if (midLevel !== undefined) {
        if (midLevel.slug === undefined || midLevel.slug === null) {
          output = '/' + parentSlug + '/' + midLevel.slug;
        } else {
          output = midLevel.slug;
        }
      } else {
        submenu?.forEach(({ slug, submenu }: IMenu) => {
          const lowLevel = submenu?.find(item => item.id === entry);
          if (lowLevel !== undefined) {
            if (lowLevel.slug !== undefined || lowLevel.slug !== null) {
              output =  slug + '/' + lowLevel.slug;
            } else {
              output = lowLevel.slug;
            }
          }
        });
      }
    });
  }
  return output;
};

export const HandleLink = ({ id, url, uri, slug }: ILink|IGridLink, menus: IMenu[]): string => {
  let realUrl = url as string;
  if(uri === undefined && url !== undefined){
    const isOutboundUrl = IsOutboundLink(url);
    if ((!IsFile(url) && !isOutboundUrl) || IsLocalboundLink(id)) {
      realUrl = GetUrlFromEntryId(menus, id);
    } 
  } else if(uri === undefined && slug !== undefined){
    realUrl = GetUrlFromEntryId(menus, id);
  } else {
    realUrl = uri as unknown as string;
  }

  return realUrl;
};

export const HandleType = ({ id, url, uri }: ILink|IGridLink): ButtonType => {
  const isOutboundUrl = url !== undefined && IsOutboundLink(url) && !IsLocalboundLink(id) && uri !== undefined;
  let realType = ButtonType.local;
  if (isOutboundUrl) {
    realType = ButtonType.url;
  }
  if ( url !== undefined && IsFile(url)) {
    realType = ButtonType.file;
  }
  return realType;
};

export const GetIdfromSlug = (menus: IMenu[], slug: string[] | string) => {  
  let joined = '';
  let outId = -1;
  let slugToFind= slug[2];

  if (Array.isArray(slug)) {
    joined = "/"+slug.join("/");
  } else {
    joined = "/"+slug;
  }

  if(typeof menus === "object"  &&  menus.length>0){
    menus?.forEach(({ slug, id, submenu }) => {
      if (slug === joined ) {
        outId = id;
      }
    
      if (submenu !== undefined && submenu?.length > 0) {
        submenu?.forEach(({slug, id, submenu}) => {
          if(slug === joined){
            outId = id;
          }

          if (submenu !== undefined && submenu?.length > 0) {
            submenu?.forEach(({slug, id}) => {
              if (slugToFind=== slug) {
                outId = id;
              }
            });
          }
        });
      }
    });
  }
  return outId;
};

export const IsFile = (text: string): boolean => {
  if (text === undefined || text === null || text === '') return false;
  var extension = text.replace(/^.*\./, '');

  if (extension === 'pdf' || extension === 'doc' || extension === 'docx') {
    return true;
  }
  return false;
};

export const IsOutboundLink = (text: string): boolean => {
  if (text === undefined || text === null || text === '') return false;
  const output = listBadUrl.map(item => {
    if (text.indexOf(item) === -1) {
      return true;
    }
  });
  return output !== undefined ?? output;
};

export const IsLocalboundLink = (entryId: number): boolean => {
  if (entryId === undefined || entryId === null || entryId === 0) return false;
  return true;
};

export const GetChildrenFromEntryId = (
  menus: IMenu[],
  entryId: number | null,
  isGrandParentCategory: boolean,
  isCategory: boolean,
): IMenu[] | undefined => {
  let childrenMenu: IMenu[] = [];

  const topLevel = menus.find(item => (entryId !== null ? item.id === entryId : ''));
  let topLevelSubmenu = topLevel?.submenu;
  if (topLevelSubmenu !== undefined) {
    if (isCategory) {
      childrenMenu = [...childrenMenu, ...topLevelSubmenu];
    }

    if (isGrandParentCategory) {
      for (let x = 0; x < topLevelSubmenu.length; x++) {
        const midLevelSubmenu = topLevelSubmenu[x]?.submenu;
        if (midLevelSubmenu !== undefined) {
          childrenMenu = [...childrenMenu, ...midLevelSubmenu];
        }
      }
    }
  } else {
    menus.forEach(element => {
      var sub = element.submenu?.find(item => item.id === entryId);

      if (sub !== null) {
        const subi = sub?.submenu as unknown as IMenu[];
        if (subi !== undefined) {
          childrenMenu = [...childrenMenu, ...subi];
        }
      }
    });
  }
  return childrenMenu;
};

export const ReplacementUrlFromEnv = (href: string): string => {
  const baseUrl = publicRuntimeConfig.BASEURL;
  let modHref: string = href;
  listBadUrl.forEach(item => {
    if (href.indexOf(item) > -1) {
      modHref = href.replace(item, baseUrl);
    }
  });

  return modHref;
};