import tw, { styled } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';
import { IColors, ICellPosition } from '../Grid';
import { Colors } from '../common';

export const GridBox = styled.section(() => {
  const out = [
    tw`flex flex-col`,
    {
      marginTop: -32,
    },
  ];
  return out;
});

export const Row = styled.div<IColors>(props => {
  const out = [
    tw`flex flex-row text-center overflow-hidden`,
    {
      maxHeight: 400,
      '>div:first-of-type': {
        borderRight: '2px solid #fff',
        borderBottom: 'none',
      },
      [`@media screen and (max-width: ${BREAKPOINTS.md}px)`]: [
        tw`flex-col overflow-visible`,
        {
          maxHeight: '100%',
          '&:nth-of-type(even)': [tw`flex-col-reverse`, {}],
          '>div:first-of-type': {
            borderRight: 'none',
            borderBottom: '2px solid #fff',
          },
          '>div': {
            borderBottom: '2px solid #fff',
          },
        },
      ],
    },
  ];
  const colors = props.colors;
  if (colors === Colors.Red) {
    out.push(tw`bg-red`);
  } else if (colors === Colors.Green) {
    out.push(tw`bg-green`);
  } else {
    out.push(tw`bg-blue`);
  }
  return out;
});

export const Cell = styled.div<ICellPosition>(props => {
  const out = [tw`flex w-full lg:w-1/2 items-center pl-0 pr-0`];
  if (props.position === 'right') {
    out.push(tw`justify-end`, {
      '>div': tw`pr-8`,
    });
  }
  if (props.position === 'left') {
    out.push(tw`justify-start`, {
      '>div': tw`pl-8`,
    });
  }
  return out;
});

export const CellContent = styled.div(() => {
  const out = [
    tw`block w-full text-center lg:text-left lg:w-2/3 p-4`,
    {
      [`@media screen and (min-width: ${BREAKPOINTS.xl2}px)`]: {
        width: '50%',
      },
      h2: tw`text-white text-2xl font-secondary leading-loose`,
      p: tw`text-white text-lg font-primary leading-loose`,
      'p + div': tw`mt-2`,
    },
  ];
  return out;
});
